import { supabase } from "../supabase";
import { DateTime } from "luxon";

let towerArrivalsChannel;
let towerDeparturesChannel;
let towerWeatherChannel;

const tower = {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state: {
    arrivals: [],
    departures: [],
    weather: null,
  },
  getters: {
    arrivals: (state) => {
      return state.arrivals || [];
    },
    departures: (state) => {
      return state.departures || [];
    },
    weather: (state) => {
      return state.weather;
    },
  },
  mutations: {
    setArrivals(state, arrivals) {
      state.arrivals = arrivals;
    },
    setDepartures(state, departures) {
      state.departures = departures;
    },
    setWeather(state, weather) {
      state.weather = weather;
    },
  },
  actions: {
    subscribe: async ({ dispatch }, airfieldId) => {
      if (towerArrivalsChannel || towerDeparturesChannel || towerWeatherChannel) await dispatch("unsubscribe");
      if (!airfieldId) {
        console.log("airfieldId", airfieldId);
        return null;
      }

      const handleArrivalsUpdates = function (/*update*/) {
        //console.debug("Realtime arrivals update:", update);
        dispatch("fetchArrivals");
      };
      const handleDeparturesUpdates = function (/*update*/) {
        //console.debug("Realtime departures update:", update);
        dispatch("fetchDepartures");
      };
      const handleWeatherUpdates = function (/*update*/) {
        //console.debug("Realtime weather update:", update);
        dispatch("fetchWeather");
      };

      towerArrivalsChannel = supabase
        .channel("tower-arrival-changes")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "arrival",
            filter: `airfield_id=eq.${airfieldId}`,
          },
          (payload) => handleArrivalsUpdates(payload)
        )
        .subscribe();

      towerDeparturesChannel = supabase
        .channel("tower-departure-changes")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "departure",
            filter: `airfield_id=eq.${airfieldId}`,
          },
          (payload) => handleDeparturesUpdates(payload)
        )
        .subscribe();

      towerWeatherChannel = supabase
        .channel("tower-weather-changes")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "weather",
            filter: `airfield_id=eq.${airfieldId}`,
          },
          (payload) => handleWeatherUpdates(payload)
        )
        .subscribe();

      dispatch("fetchArrivals");
      dispatch("fetchDepartures");
      dispatch("fetchWeather");
    },
    unsubscribe: async () => {
      if (towerArrivalsChannel) {
        await supabase.removeChannel(towerArrivalsChannel);
      }
      if (towerDeparturesChannel) {
        await supabase.removeChannel(towerDeparturesChannel);
      }
      if (towerWeatherChannel) {
        await supabase.removeChannel(towerWeatherChannel);
      }
    },

    fetchArrivals: async ({ rootGetters, commit }) => {
      if (!rootGetters["auth/airfieldId"]) return null;

      let query = supabase
        .from("arrival")
        .select()
        .eq("airfield_id", rootGetters["auth/airfieldId"])
        .order("date_time", { ascending: true });

      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10);

      query = query.eq("date", today);

      const { data, error } = await query;

      if (error) console.error(error);

      commit("setArrivals", data);
    },

    fetchDepartures: async ({ rootGetters, commit }) => {
      if (!rootGetters["auth/airfieldId"]) return null;

      let query = supabase
        .from("departure")
        .select()
        .eq("airfield_id", rootGetters["auth/airfieldId"])
        .order("date_time", { ascending: true });

      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10);

      query = query.eq("date", today);

      const { data, error } = await query;

      if (error) console.error(error);

      commit("setDepartures", data);
    },

    fetchWeather: async ({ rootGetters, commit }) => {
      if (!rootGetters["auth/airfieldId"]) return null;

      let query = supabase
        .from("weather")
        .select()
        .eq("airfield_id", rootGetters["auth/airfieldId"])
        .order("date_time", { ascending: false })
        .limit(1)
        .maybeSingle();

      const { data, error } = await query;

      if (error) console.error(error);

      commit("setWeather", data);
    },

    newArrival: async ({ rootGetters }, data) => {
      const docData = {
        ...data,
        airfield_id: rootGetters["auth/airfieldId"],
        status:
          rootGetters["auth/operationMode"] === "attended" ? "New" : "Approved",
        source: "airfield",
        aircraft_reg: data.aircraft_reg.toUpperCase(),
        aircraft_type: data.aircraft_type.toUpperCase(),
        date_time: DateTime.fromISO(`${data.date}T${data.time}:00`)
          .toUTC()
          .toISO(),
      };
      await supabase.from("arrival").insert(docData);
    },

    newDeparture: async ({ rootGetters }, data) => {
      const docData = {
        ...data,
        airfield_id: rootGetters["auth/airfieldId"],
        status: "New",
        source: "airfield",
        aircraft_reg: data.aircraft_reg.toUpperCase(),
        aircraft_type: data.aircraft_type.toUpperCase(),
        date_time: DateTime.fromISO(`${data.date}T${data.time}:00`)
          .toUTC()
          .toISO(),
      };
      await supabase.from("departure").insert(docData);
    },

    markArrivalAs: async (_, data) => {
      await supabase
        .from("arrival")
        .update({ status: data.status })
        .eq("id", data.itemId);
    },

    markArrivalAsRejected: async (_, data) => {
      await supabase
        .from("arrival")
        .update({
          status: "Rejected",
          rejection_message: data.rejectionMessage,
        })
        .eq("id", data.itemId);
    },

    markDepartureAs: async (_, data) => {
      await supabase
        .from("departure")
        .update({ status: data.status })
        .eq("id", data.itemId);
    },
  },
};

export default tower;
