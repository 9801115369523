<template>
  <v-app-bar
    app
    fixed
    color="navbackground"
    dark
  >
    <v-container class="py-0 px-0 px-md-3 fill-height">
      <v-btn
        text
        rounded
        color="navbackground"
        to="/"
        aria-label="AirfieldHub Logo to home"
      >
        <div class="logo2" />
        <!--  <v-img
          src="../assets/logo.svg"
          alt="AirfieldHub"
          width="234px"
          contain
          class="logo"
        /> -->
      </v-btn>

      <v-spacer />
        

      <v-toolbar-items
        class="d-none d-sm-flex"
        color="navcolour"
      >
        <v-btn
          v-if="isLoggedIn"
          text
          rounded
          color="navcolour"
          to="/admin/dashboard"
        >
          <v-icon left>
            mdi-home
          </v-icon>
          <span>Dashboard</span>
        </v-btn>
        <v-btn
          v-if="!isLoggedIn"
          text
          rounded
          color="navcolour"
          to="/features"
        >
          <span>Features</span>
        </v-btn>
        <v-btn
          v-if="!isLoggedIn"
          text
          rounded
          color="navcolour"
          to="/pricing"
        >
          <span>Pricing</span>
        </v-btn>
        <v-divider
          vertical
          v-if="!isLoggedIn"
          class="mx-2 special_bg_class"
        />
        <v-btn
          v-if="!isLoggedIn"
          text
          rounded
          color="navcolour"
          to="/register"
        >
          <v-icon class="me-2">
            mdi-rocket-launch
          </v-icon>
          <span>Sign Up</span>
        </v-btn>
        <v-btn
          v-if="!isLoggedIn"
          text
          rounded
          color="navcolour"
          to="/login"
        >
          <v-icon class="me-2">
            mdi-login
          </v-icon>
          <span>Login</span>
        </v-btn>
        <v-btn
          v-if="isLoggedIn"
          class="ml-4"
          text
          rounded
          color="navcolour"
          @click="logout"
        >
          <v-icon left>
            mdi-logout
          </v-icon>
          <span>Log Out</span>
        </v-btn>

        <theme-toggle />
      </v-toolbar-items>

      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex d-sm-none">
            <v-btn
              v-if="isLoggedIn"
              icon
              to="/admin/dashboard"
              title="Dashboard"
              color="navcolour"
              aria-label="Home button"
            >
              <v-icon>
                mdi-home
              </v-icon>
            </v-btn>
            <theme-toggle />
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              color="navcolour"
              aria-label="Settings"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
        </template>

        <v-list>
          <v-list-item
            v-if="!isLoggedIn"
            to="/features"
          >
            <v-list-item-icon />
            <v-list-item-content>
              <v-list-item-title>
                Features
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!isLoggedIn"
            to="/pricing"
          >
            <v-list-item-icon />
            <v-list-item-content>
              <v-list-item-title>
                Pricing
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!isLoggedIn"
            to="/register"
          >
            <v-list-item-icon>
              <v-icon>mdi-rocket-launch</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Sign Up
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!isLoggedIn"
            to="/login"
          >
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Login
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isLoggedIn"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Sign Out
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
import ThemeToggle from "./ThemeToggle.vue";

export default {
  name: "NavBar",

  components: { ThemeToggle },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    hasAirfield() {
      return this.$store.state.auth.airfieldDocument !== null;
    },
    isInAdmin() {
      return this.$route.matched.some(({ path }) => path.startsWith("/admin"));
    }
  },

  methods: {
    ...mapActions({
      logout: "auth/logout"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px;
  }
  .logo {
    max-width: 38vw;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  .logo {
    max-width: 28vw;
  }
}

.logo2{
  width: 240px;
  height: 35px;
  background-color: var(--v-navcolour-base);
  -webkit-mask: url('~@/assets/logo.svg') no-repeat center;
  mask: url('~@/assets/logo.svg') no-repeat center;
}

.special_bg_class{
  border-color: var(--v-navcolour-base);
  opacity: 0.2;
}

</style>
