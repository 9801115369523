<template>
  <v-container class="other_pages">
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
        lg="4"
        xl="3"
      >
        <h2 class="text-h4 my-4">
          Sign In
        </h2>
        <validation-observer ref="observer">
          <v-form
            ref="form"
            lazy-validation
            :disabled="isSubmitting"
            @submit.prevent="sendLink"
          >
            <v-card>
              <v-card-text>
                <p
                  v-if="showLinkSent"
                  class="text-body-1"
                >
                  We've sent you a magic link to <b>{{ email }}</b>, please click the link in your email to complete your login.
                </p>
                <p
                  v-if="showLinkSent"
                  class="text-body-1"
                >
                  If you haven't received an email yet, please be sure you are
                  using the email used to create your airfield originally.
                </p>
                <p
                  v-if="!showLinkSent"
                  class="text-body-1"
                >
                  Enter your airfield email address and we'll send you a magic
                  link to login.
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-if="!showLinkSent"
                    label="Email Address"
                    type="email"
                    autocomplete="email"
                    required
                    v-model="email"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!showLinkSent"
                  color="primary"
                  type="submit"
                  class="mb-4"
                  :disabled="isSubmitting"
                >
                  Sign In
                </v-btn>
                <v-spacer />
              </v-card-actions>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  small
                  color="primary"
                  to="/register"
                >
                  Need to create an airfield?
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { supabase } from "../supabase";
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";

const _getEmail = function() {
  return window.localStorage.getItem("emailForLogin");
};
const _setEmail = function(email) {
  return window.localStorage.setItem("emailForLogin", email);
};

const _doLogin = async loginData => {
  console.log("PRE SEND ==> login url setting here ")
  const { error } = await supabase.functions.invoke('authentication-loginLink', {
    body: {
      email: loginData.email,
      url: window.location.origin + "/magicLinkLogin",
    }
  });
  console.log("PRE SEND ==> ", window.location.origin + "/magicLinkLogin");

  if (error) {
    return console.error(error);
  }

  _setEmail(loginData.email);
  loginData.showLinkSent = true;
};

export default {
  name: "Login",
  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Please enter an email address"
    });

    extend("email", {
      ...email,
      message: "Email must be valid"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    isSubmitting: false,
    email: _getEmail() || "",
    showLinkSent: false
  }),

  methods: {
    sendLink: function() {
      this.isSubmitting = true;
      let data = this;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            await _doLogin(data);
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
