<template>
  <div :class="{ webp: WebpIsSupported, no_wepg: !WebpIsSupported }">
    <admin-page v-if="isAdminPage()">
      <router-view />
    </admin-page>
    <marketing-page v-else>
      <router-view />
    </marketing-page>
  </div>
</template>
<!--       <router-view />
 -->
<script>
import AdminPage from "./components/AdminPage.vue";
import MarketingPage from "./components/MarketingPage.vue";

export default {
  name: "App",

  beforeMount() {
    this.$store.dispatch("initTheme");
  },

  components: {
    AdminPage,
    MarketingPage
  },

  methods: {
    isAdminPage: function() {
      return this.$route.path.startsWith("/admin");
    },
    WebpIsSupported: function(){
      if(!window.createImageBitmap){
        return false;
      }
      // Base64 representation of a white point image
      var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

      // Retrieve the Image in Blob Format
      fetch(webpdata).then(function(response){
          return response.blob();
      }).then(function(blob){
          // If the createImageBitmap method succeeds, return true, otherwise false
          createImageBitmap(blob).then(function(){
              return true;
          }, function(){
              return false;
          });
      });
    }
  }
};
</script>
