import {supabase} from "../supabase";
import DatesHelper from "./dates-helper";
import {DateTime} from "luxon";

const filterDateDefault = "today";

let departuresChannel;
const departures = {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state: {
    items: [],
    itemsFilter: {
      date: filterDateDefault,
      dateText: DatesHelper.textFromFilterType(filterDateDefault)
    }
  },
  getters: {
    items: state => {
      return state.items || [];
    },
    today: state => {
      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10);
      return (
        state.items?.filter(item => {
          return item.date === today;
        }) || []
      );
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    filterDateBy(state, type) {
      state.itemsFilter.date = type;
      state.itemsFilter.dateText = DatesHelper.textFromFilterType(type);
    }
  },
  actions: {
    subscribe: async ({rootGetters, dispatch}, payload = {}) => {
      if (departuresChannel) await dispatch("unsubscribe");
      if (!rootGetters["auth/airfieldId"]) return null;

      const handleUpdates = function (update) {
        console.debug("Realtime update:", update);
        dispatch("fetch", payload);
      };

      departuresChannel = supabase
        .channel('departure-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'departure',
            filter: `airfield_id=eq.${rootGetters["auth/airfieldId"]}`,
          },
          (payload) => handleUpdates(payload)
        )
        .subscribe()

      dispatch("fetch", payload);
    },
    unsubscribe: async () => {
      if (departuresChannel) {
        await supabase.removeChannel(departuresChannel);
      }
    },

    fetch: async ({rootGetters, state, commit}, payload = {}) => {
      if (!rootGetters["auth/airfieldId"]) return null;

      let query = supabase.from("departure")
        .select()
        .eq("airfield_id", rootGetters["auth/airfieldId"])
        .order("date_time", {ascending: true});

      if (payload.forToday) {
        const today = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);

        query = query.eq("date", today);
      } else {
        const range = DatesHelper.rangeFromFilterType(state.itemsFilter.date);

        query = query
          .gte("date_time", range.from.toISO())
          .lt("date_time", range.to.toISO());
      }

      const {data, error} = await query;

      if (error) console.error(error);

      commit("setItems", data);
    },

    filterDateBy: async ({commit, dispatch}, type) => {
      await commit("filterDateBy", type);
      dispatch("subscribe");
    },

    new: async ({rootGetters}, data) => {
      const docData = {
        ...data,
        airfield_id: rootGetters["auth/airfieldId"],
        status: "New",
        source: "airfield",
        aircraft_reg: data.aircraft_reg.toUpperCase(),
        aircraft_type: data.aircraft_type.toUpperCase(),
        date_time: DateTime.fromISO(`${data.date}T${data.time}:00`).toUTC().toISO()
      };
      await supabase.from("departure").insert(docData);
    },

    markAs: async (_, data) => {
      await supabase.from("departure")
        .update({status: data.status})
        .eq("id", data.itemId);
    }
  }
};

export default departures;
