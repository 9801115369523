<template>
  <v-container class="other_pages">
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
        lg="4"
      >
        <validation-observer ref="observer">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="login"
          >
            <v-card v-if="showEmailRequired">
              <v-card-title>
                <span class="text-h5">Magic Link Login</span>
              </v-card-title>
              <v-card-text>
                <p class="body-1">
                  Please enter you email address again, as you requested this
                  link in another browser or device.
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    label="Email"
                    required
                    v-model="email"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  type="submit"
                >
                  Login
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title>
                <span class="text-h5">Magic Link Login</span>
              </v-card-title>
              <v-card-text>
                <p class="body-1">
                  Logging you in now...
                </p>
              </v-card-text>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {required, email} from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";
import { mapGetters } from "vuex";

const _getEmail = function () {
  return window.localStorage.getItem("emailForLogin");
};
const _setEmail = function (email) {
  return window.localStorage.setItem("emailForLogin", email);
};

const _doLogin = async $this => {
  try {
    window.localStorage.removeItem("emailForLogin");

    $this.$router.replace("/admin/dashboard");
  } catch (error) {
    console.error(error);
    if (error.code == "auth/invalid-action-code") {
      $this.$router.replace("/login");
    }
  }
};

export default {
  name: "MagicLinkLogin",
  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Please enter an email address"
    });

    extend("email", {
      ...email,
      message: "Email must be valid"
    });

    console.debug('this.isLoggedIn', this.isLoggedIn);
    if (this.isLoggedIn) {
      _doLogin(this);
    }
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    isSubmitting: false,
    email: "",
    showEmailRequired: _getEmail() ? false : true
  }),

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },

  methods: {
    login: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            _setEmail(this.email);
            await _doLogin(this);
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
