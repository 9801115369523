<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ userName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <theme-toggle />
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        v-if="airfields.length === 0"
      >
        <v-list-item-icon>
          <v-icon>mdi-airplane</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ airfieldName }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="airfieldIcaoCode">
            {{ airfieldIcaoCode }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-else
        @click.stop
        prepend-icon="mdi-airplane"
        color="navcolour"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ airfieldName }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="airfieldIcaoCode">
              {{ airfieldIcaoCode }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="airfield in airfields"
          :key="airfield.id"
          link
          @click="switchAirfield(airfield.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ airfield.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="airfield.icao_code">
              {{ airfield.icao_code }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        color="primary"
        dense
        class="justify-center"
      >
        <v-btn
          to="/admin/airfield/new"
          x-small
          text
          color="grey"
        >
          Create new airfield
        </v-btn>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list>
      <v-list-item
        v-if="isLoggedIn"
        color="navcolour"
        @click="logout"
      >
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ThemeToggle from "./ThemeToggle.vue";

export default {
  name: "UserMenu",

  components: {
    ThemeToggle,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      userName: "auth/userName",
      userEmail: "auth/userEmail",
      airfields: "auth/airfields",
      airfieldId: "auth/airfieldId",
      airfieldName: "auth/airfieldName",
      airfieldIcaoCode: "auth/airfieldIcaoCode",
    })
  },

  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),
    switchAirfield(id) {
      this.$store.dispatch("auth/switchAirfield", id);
    },
  }
};
</script>
