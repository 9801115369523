<template>
  <div class="home_wrapper">
    <main id="home_main">
      <section class="parallax tos_bg">
        <v-container
          fill-height
          fluid
        >
          <v-row   
            vertical-align="bottom"
            class="override_home_welcome"
          >
            <v-col
              align="end" 
              class="text-center"
              cols="12"
            >
              <v-row class="my-8">
                <v-col class="text-center">
                  <h1 class="text-h3 mb-8 text-center force-center-title">
                    Terms of Service
                  </h1>
                  <p class="text-h5">
                    Some more small print...
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="no-parallax longer_content main_block">
        <v-container>
          <v-row
            justify="center"
            class="text-left"
          >
            <v-col class="col-12">
              <div class="row">
                <h3>Acceptance</h3>
                <p>
                  This Terms of Use Agreement ("Agreement") is a legal agreement between you and AirfieldHub Limited. ("AirfieldHub"), 
                  the owner and operator of this Web site (the "Web site"). It states the terms and conditions under which
                  you may access and use the Web site and all written and other materials displayed or made available through the
                  Web site, including, without limitation, articles, text, photographs, images, illustrations, audio clips, video
                  clips, computer software and code (the "Content"). By accessing and using the Web site, you are indicating your
                  acceptance to be bound by the terms and conditions of this Agreement. If you do not accept these terms and
                  conditions, you must not access or use the Web site. AirfieldHub may revise this Agreement at any time by updating
                  this posting. Use of the Web site after such changes are posted will signify your acceptance of these revised
                  terms. You should visit this page periodically to review this Agreement.
                </p>

                <h3>Disclaimer of Warranties</h3>
                <p>
                  THE WEB SITE AND THE CONTENT IS PROVIDED "AS IS". WHILE AirfieldHub ENDEAVOURS TO PROVIDE INFORMATION THAT IS
                  CORRECT, ACCURATE AND TIMELY, AirfieldHub MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING
                  THE WEB SITE AND THE CONTENT INCLUDING, WITHOUT LIMITATION, NO REPRESENTATION OR WARRANTY THAT (I) THE CONTENT
                  CONTAINED IN OR MADE AVAILABLE THROUGH THE WEB SITE WILL BE OF MERCHANTABLE QUALITY AND FIT FOR A PARTICULAR
                  PURPOSE, (II) THE WEB SITE OR CONTENT WILL BE ACCURATE, COMPLETE, CURRENT, RELIABLE, OR TIMELY, (III) THAT THE
                  OPERATION OF THE WEB SITE WILL BE UNINTERRUPTED OR ERROR-FREE, (IV) THAT DEFECTS OR ERRORS IN THE WEB SITE OR THE
                  CONTENT, BE IT HUMAN OR COMPUTER ERRORS, WILL BE CORRECTED, (V) THAT THE WEB SITE WILL BE FREE FROM VIRUSES OR
                  HARMFUL COMPONENTS, AND (VI) THAT COMMUNICATIONS TO OR FROM THE WEB SITE WILL BE SECURE AND/OR NOT INTERCEPTED.
                </p>
                <h3>Limitation of Liability</h3>
                <p>
                  SUBJECT TO APPLICABLE LAW, IN NO EVENT SHALL AirfieldHub, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS,
                  AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY
                  DIRECT, SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES INCLUDING, WITHOUT LIMITATION, ANY LOSS OR DAMAGES
                  IN THE NATURE OF, OR RELATING TO, LOST BUSINESS, LOST PROFIT, BUSINESS INTERRUPTION, INACCURATE INFORMATION, OR
                  ANY OTHER LOSS INCURRED IN CONNECTION WITH THE USE OR MISUSE OF THE WEB SITE, REGARDLESS OF THE CAUSE AND WHETHER
                  ARISING IN CONTRACT (INCLUDING FUNDAMENTAL BREACH), TORT (INCLUDING NEGLIGENCE), OR OTHERWISE. THE FOREGOING
                  LIMITATION SHALL APPLY EVEN IF AirfieldHub KNEW OF OR OUGHT TO HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <h3>Ownership</h3>
                <p>
                  The Content is protected by copyright law, and is owned by AirfieldHub, its licensors, and/or the party accredited
                  as the provider of the Content. Except as granted in the limited license herein, any use of the Content, including
                  modification, transmission, presentation, distribution, republication, or other exploitation of the Web site or of
                  its Content, whether in whole or in part, is prohibited without the express prior written consent of AirfieldHub.
                </p>
                <h3>Limited Licence</h3>
                <p>
                  Notwithstanding the foregoing, and subject to the terms and conditions of this Agreement, you are hereby granted a
                  limited, non-transferable and non-exclusive license to access, view and use the Web site for your personal,
                  noncommercial use, and are granted the right to download, store and print single copies of items comprising the
                  Content for your personal, non-commercial use, provided that you maintain all copyright and other notices
                  contained in such Content. If you violate any provision of this Agreement, your permission to use the Content
                  automatically terminates and you must immediately destroy any copies you have made of any Content.
                </p>
                <h3>Linking</h3>
                <p>
                  The Web site may contain links to third-party web sites. These links are provided solely as a convenience to you
                  and not as an endorsement by AirfieldHub of the contents of such third-party web sites. AirfieldHub is not
                  responsible for the content of any third-party web site, nor does it make any representation or warranty of any
                  kind regarding any third-party web site including, without limitation, (i) any representation or warranty
                  regarding the legality, accuracy, reliability, completeness, timeliness or suitability of any content on such
                  third-party web sites, (ii) any representation or warranty regarding the merchantability and fitness for a
                  particular purpose of any material, content, software, goods, or services located at or made available through
                  such third-party web sites, or (iii) any representation or warranty that the operation of such third-party web
                  sites will be uninterrupted or error free, that defects or errors in such third-party web sites will be corrected,
                  or that such third-party web sites will be free from viruses or other harmful components. While AirfieldHub
                  encourages links to the Web site, it does not wish to be linked to or from any third-party web site which
                  contains, posts or transmits any unlawful information of any kind, including, without limitation, any content
                  constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or
                  otherwise violate any local, state, provincial, national or international law, regulation which may be damaging or
                  detrimental to the activities, operations, credibility or integrity of AirfieldHub or which contains, posts or
                  transmits any information, software or other material which violates or infringes upon the rights of others,
                  including material which is an invasion of privacy or publicity rights, or which is protected by copyright,
                  trademark or other proprietary rights. AirfieldHub reserves the right to prohibit or refuse to accept any link to
                  the Web site, including, without limitation, any link which contains or makes available any content or information
                  of the foregoing nature, at any time. You agree to remove any link you may have to the Web site upon the request
                  of AirfieldHub.
                </p>
                <h3>Feedback</h3>
                <p>
                  Any comments or materials ("Submissions") that you send to AirfieldHub shall be treated as non-confidential unless
                  otherwise agreed between you and AirfieldHub in writing. You hereby grant AirfieldHub a royalty-free, perpetual,
                  non-exclusive, irrevocable, unrestricted, worldwide license to (i) use, reproduce, store, adapt, translate,
                  modify, make derivative works from, transmit, distribute, publicly perform or display such Submissions for any
                  purpose, and (ii) to sublicense to third parties the unrestricted right to exercise any of the foregoing rights.
                  In addition to the grant of the above license, you hereby (i) agree to waive all moral rights in your Submission
                  in favour of AirfieldHub, (ii) consent to your name, address and e-mail appearing as the contributor of your
                  Submission, where applicable, and to the disclosure and display of such information and any other information
                  which appears in or is associated with your Submission, (iii) acknowledge and agree that AirfieldHub is not
                  responsible for any loss, damage or corruption that may occur to your Submission, and (iv) acknowledge and agree
                  that your Submission will be non-confidential.
                </p>
                <h3>Security</h3>
                <p>
                  Any information sent or received over the internet is generally unsecure. AirfieldHub cannot guarantee the
                  security of any communication to or from the Web site.
                </p>
                <h3>General Terms</h3>
                <p>
                  This Agreement constitutes the entire agreement between you and AirfieldHub with respect to the subject matter
                  hereof. This Agreement shall inure to the benefit of, and be binding upon you and AirfieldHub and each of our
                  respective successors and assigns. The invalidity or unenforceability of any provision of this Agreement or any
                  covenant contained herein shall not affect the validity or enforceability of any other provision or covenant
                  contained herein and any such invalid provision or covenant shall be deemed severable from the rest of this
                  Agreement.
                </p>
                <h3>Governing Law and Jurisdiction</h3>
                <p>
                  By accessing or using the Web site, you agree that all matters relating to your access to, or use of the Web site
                  and its Content shall be governed by the laws of England without regard to conflict of laws principles. You agree
                  and hereby submit to the exclusive jurisdiction of The High Court in London with respect to all matters relating
                  to the Website.
                </p>
                <h3>Internet Privacy Statement</h3>
                <p>
                  At AirfieldHub, your privacy is very important to us. AirfieldHub is committed to providing visitors with websites
                  that respect their privacy. This statement sets out our privacy practices with respect to the collection, use and
                  disclosure of personal information on this site.
                </p>
                <p>
                  This site may contain links that take you to other websites. This privacy statement does not extend to the data
                  collection practices of third parties and AirfieldHub does not assume any responsibility for the privacy
                  practices, policies or actions of third parties. You should read the individual privacy policies and/or legal
                  notices of such sites and make an informed decision as to whether or not you wish to use these sites based on
                  their privacy practices.
                </p>
                <p>
                  Personal information means data or information about an identifiable person, recorded in any form, which data or
                  information includes but is not limited to the name, e-mail address and mailing address, as the case may be.
                  Visitors of this website are not requested to reveal any personal information. However, on a voluntary basis, you
                  may provide and disclose personal information to us in using certain features of our site.
                </p>
                <p>
                  AirfieldHub will never disclose, sell or lease to any third party, either free of charge or for remuneration, any
                  personal information that you provide on this site without your permission, unless we are required to do so by
                  law. Notwithstanding this, certain sections of this website could be activated and hosted by a third party.
                  Accordingly, the personal information provided to us through these sections will be available to such third party,
                  its affiliated companies and authorised sub-contractors for the limited purposes of hosting and administering
                  these particular sections.
                </p>
                <p>
                  If you submit an e-mail inquiry to this site, any personal information you might submit at the same time will only
                  be used by AirfieldHub employees or its affiliates who need to have access to it in order to respond to your
                  inquiry.
                </p>
                <p>
                  AirfieldHub's Web Server may collect visitor information regarding which pages are accessed. This information is
                  used internally, only in aggregate form, to better serve visitors by helping AirfieldHub to manage its websites,
                  diagnose any technical problems and improve the content of its websites. We use Google Analytics as our third
                  party provider for this task.
                </p>
                <p>
                  Your personal information is treated as private, confidential information by AirfieldHub and we strive to ensure
                  that your personal information is protected at all times.
                </p>
                <p>
                  However, even though AirfieldHub has implemented methods designed to ensure the protection of your personal
                  information that are considered to be effective, we cannot guarantee that such information will not be lost, used
                  unlawfully or modified in a fraudulent manner since no data transmission over the Internet can be guaranteed to be
                  completely confidential. Consequently, AirfieldHub bears no liability for the use that may be made of the
                  information by you or any third party.
                </p>
                <p>
                  AirfieldHub reserves the right to change this privacy statement as it sees fit and without prior notice. However,
                  we will ensure that any change to this statement is in compliance with the laws in effect in England with regard
                  to this subject matter and posted on this page. We encourage you to refer back to this privacy statement
                  regularly.
                </p>
                <p>
                  If you have any comments, questions or complaints regarding AirfieldHub's privacy statement or practices, please
                  send an email to hello@airfieldhub.com
                </p>

                <h3>Privacy Addendum</h3>
                <p>Definitions and Interpretation In this Policy, the following terms shall have the following meanings:</p>
                <p>
                  "personal data" means any and all data that relates to an identifiable person who can be directly or indirectly
                  identified from that data. In this case, it means personal data that you give to Us via Our Site. This definition
                  shall, where applicable, incorporate the definitions provided in the Data Protection Act 1998 and EU Regulation
                  2016/679 – the General Data Protection Regulation (“GDPR”); and “We/Us/Our” means AirfieldHub Limited, a limited
                  company registered in England and Wales under company number: 13373459, registered office: AirfieldHub, Cadogan Square, London, SW1x 0HU, 
                  whose main trading address is: AirfieldHub, Cadogan Square, London, SW1x 0HU
                </p>
                <p>
                  Our Data Protection Officer is Alex Reynier, and can be contacted by email at alex@airfieldhub.com, or by post to
                  Alex Reynier, Data Protection Office, AirfieldHub, Cadogan Square, London, SW1X 0HU.
                </p>
                <p>
                  We only collect personal data which you directly provide us, either by telephone, email or by submitting a form
                  from our Site. This data is governed by the Legitimate Interest clause of the EUGDPR regulations. This data may
                  include, but is not limited to: Name, Email Address, Phone Number, Weight, Height, Voucher Details, Business /
                  Company Name, Next of Kin Details.
                </p>
                <p>
                  All personal data is processed and stored securely, for no longer than is necessary in light of the reason(s) for
                  which it was first collected or given. We will comply with our obligations and safeguard your rights under the
                  Data Protection Act 1998 and EUGDPR regulations at all times.
                </p>
                <p>
                  We may sometimes contract with third parties to supply products and services to you on Our behalf. These may
                  include payment processing, delivery of goods, search engine facilities, advertising, and marketing. In some
                  cases, the third parties may require access to some or all of your data. Where any of your data is required for
                  such a purpose, We will take all reasonable steps to ensure that your data will be handled safely, securely, and
                  in accordance with your rights, Our obligations, and the obligations of the third party under the laws of England.
                </p>
                <p>
                  We may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of
                  control of all or part of our business. Any personal data that you have provided will, where it is relevant to any
                  part of our business that is being transferred, be transferred along with that part and the new owner or newly
                  controlling party will, under the terms of this Privacy Policy, be permitted to use that data only for the same
                  purposes for which it was originally collected by us. In the event that any of your data is to be transferred in
                  such a manner, you will be contacted in advance and informed of the changes. When contacted you will, however, be
                  given the choice to have your data deleted or withheld from the new owner or controller.
                </p>
                <p>
                  You have the right to ask for a copy of any of your personal data held by us (where such data is held) with no fee
                  payable. We will provide any and all information in response to your request free of charge. Please contact us for
                  more details at hello@airfieldhub.com, or using the contact form on our contact page.
                </p>

                <h3>Notice</h3>
                <p>
                  Questions or comments regarding the Web site should be directed by e-mail to hello@airfieldhub.com
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="no-parallax shorter_content">
        <footer-bar />
      </section>
    </main>
  </div>
</template>
<style>
.main_block h3{
    clear: both;
    float: none;
    width: 100%;
    display: block;
}
</style>
<script>
import FooterBar from "../components/FooterBar.vue";

export default {
  name: "TermsOfService",
  components: { FooterBar}
};
</script>
