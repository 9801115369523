<template>
  <div class="home_wrapper">
    <main id="home_main">
      <section class="parallax tnc_bg half_height">
        <v-container
          fill-height
          fluid
        >
          <v-row   
            vertical-align="bottom"
            class="override_home_welcome"
          >
            <v-col
              align="end" 
              class="text-center"
              cols="12"
            >
              <v-row class="my-8">
                <v-col class="text-center">
                  <h1 class="text-h3 mb-8 text-center force-center-title">
                    Terms &amp; Conditions
                  </h1>
                  <p class="text-h5">
                    Some more small print...
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="no-parallax longer_content main_block">
        <v-container>
          <v-row
            justify="center"
            class="text-left"
          >
            <v-col class="col-12">
              <div class="row">
                <h3>Statement of Trust</h3>
                <p>
                  We trade on the basis of a mutual trust, between our customers and our staff. We believe that our staff work hard
                  to provide and maintain a useable service at a fair price, which customers then enjoy and appreciate.
                </p>
                <h3>Product Descriptions</h3>
                <p>
                  Whilst every care has been taken to provide an accurate visual representation of our services, individual screen
                  sizes and settings may alter individual colour reproduction. In addition, because screen technology is still being
                  improved, occasionally your monitor may be unable to do full justice to some of the images depicted.
                </p>
                <h3>Pricing and Payment</h3>
                <p>
                  All goods offered for sale through our website are priced in pounds sterling and are currently not liable for VAT. AirfieldHub
                  Limited will remain the owner of any goods or services sold until full payment for such goods or services is
                  received from the customer.
                </p>
                <h3>Service Conditions</h3>
                <p>
                  Except as otherwise expressly mentioned in these conditions, AirfieldHub Limited, shall not be liable for any loss
                  or damage (whether direct or indirect) to the customer or a third party due to negligence during the tour. This
                  does not affect your Statutory Rights under the Sale of Goods Act 1979.
                </p>

                <h3>PPR Conditions</h3>
                <p>
                  All PPR requests processed by AirfieldHub LTD are accepted by the respective airfields and are subject to adhering to their flying instructions and noise abatement. Any aircraft
                  found to not follow instructions, or purposefully breaching our guidelines for anything other than a real
                  emergency may be fined, or its pilots banned from returning to the airfield in question. Whilst AirfieldHub encourages prospective pilots to ensure they have read all relevant NOTAM, weather, joining instructions, and airfield plates, AirfieldHub takes no responsibility in the competency of the pilot, or their adequate briefing prior to departure.
                </p>

                <h3>Customer Satisfaction</h3>
                <p>
                  We take the greatest care to ensure all our service offers the highest level of customer satisfaction and
                  enjoyment, if however a customer is unsatisfied , we will make every effort to rectify the situation to ensure the
                  customer is fully satisfied. If a customer would like to make a complaint the matter should be raised with
                  AirfieldHub customer services. He or she will do everything within their immediate power to resolve the matter. If
                  that is not possible, they will take the matter to a Company Director, who will take on the personal
                  responsibility and ownership of the matter until complete resolution has been achieved.
                </p>
                <h3>Privacy Policy</h3>
                <p>
                  AirfieldHub Limited maintains a strict privacy policy. Because we are committed to protecting customer privacy, we
                  will only use the information we collect about customers lawfully in accordance with the Data Protection Act 1998,
                  EUGDPR and according to recommended codes of practice. E-mails we receive and send are kept on our computer
                  systems to ensure that we have a record of all orders placed. However, because the storage of personal information
                  is covered by legislation, we have adopted a simple policy of not disclosing personal information to third parties
                  other than where necessary to fulfil PPR requests, or upon request by a professional body (ie: the UK CAA or NATS). This policy offers total peace of mind and means customers
                  shouldn’t be pestered by unsolicited telephone calls, e-mails or junk mail.
                </p>
                <p>
                  We also use this information to update visitors who have registered their details with us, and requested to be
                  kept updated of our latest products. We collect information about our customers to process orders, and to provide
                  the best possible service and also where customers have requested us to keep details. The information we collect
                  about customers includes but is not limited to their name, address, telephone number, e-mail address and, where it
                  has been supplied, as well as details of any particular areas of interest.
                </p>
                <p>
                  This information will have been supplied by the customer when requesting PPR or further information or updates. We
                  do not hold your credit/debit card details, and use them for the sole purpose of carrying out the transaction to
                  purchase our products.
                </p>
                <p>
                  From time to time we may inform our customers of new products, or events that we introduce, and may solicit their
                  comments for the purpose of improving our service. Customers who do not wish to receive mailings are able to
                  unsubscribe by clicking the link at the bottom of the emails.
                </p>
                
                <h3>Cancellation &amp; Date Changes</h3>
                <p>
                  PPR is granted for a specific day, pilot and specific aircraft. Any changes to the date, pilot or aircraft type
                  will require you to alter your PPR request.
                </p>
                <p>
                  Cancellation of PPR requests are not mandatory, but will be gratefully received.
                </p>

                <h3>AirfieldHub Charges &amp; Payment Liability</h3>
                <p>
                  When a payment is made through our platform, these payments are made via our secure payment partner Stripe. Stripe is responsible for the transaction taking place, and the security of your details. Money is NOT transferred to AirfieldHub, money is directly transferred to the respective airfield whose services you are paying for. 
                </p>
                <p>
                  Airfields themselves are responsible for any administration regarding the payments, airfields have sole discretion over payment management, AirfieldHub is not liable for any payment administration, and are unable to provide help with regards to transactions. Pilots should contact the respective airfield.
                </p>
                <p>
                  Whilst AirfieldHub does not have any jurisdiction over transactions, we would be grateful to know should a particular airfield is not cooperating with pilots.
                </p>
                <p>
                  AirfieldHub takes a tiny commission on each transaction, these collected funds arrive at AirfieldHub after funds are transferred to the respective airfield. These funds enable us to provide airfields and pilots with our services, and for us to grow our offering to airfields. At the discretion of AirfieldHub these fees may be refunded should a refund action be taken by an airfield.
                </p>

                <h3>Force Majeure</h3>
                <p>
                  Should other circumstances outside of our control arise, which could not have been prevented despite all efforts
                  and precautions on our side – for example Acts of God, riots, civil unrest, Civil Aviation Authority Restrictions,
                  put stop to an event, we may be required to close the service. Any otherwise approved PPR requests will continue to be valid with the airfield, but no further requests may be valid.
                </p>
                <p>
                  These terms do change periodically, and it is the responsability of the purchasor to keep up to date.
                </p>

                <h3>Copyright</h3>
                <p>
                  We are the originators of the AirfieldHub LTD and offer these in good faith to our customers on the
                  understanding that they then do not copy / steal / plagiarise any or all of our content that we have carefully
                  developed for the enjoyment of our customers.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="no-parallax shorter_content">
        <footer-bar />
      </section>
    </main>
  </div>
</template>
<style>
.main_block h3{
    clear: both;
    float: none;
    width: 100%;
    display: block;
}
.half_height{
  height:  100vh;
}
</style>
<script>
import FooterBar from "../components/FooterBar.vue";

export default {
  name: "TermsAndConditions",
  components: { FooterBar}
};
</script>
