<template>
  <div class="home_wrapper">
    <main id="home_main">
      <section class="parallax not_found_bg">
        <v-container
          fill-height
          fluid
        >
          <v-row   
            vertical-align="bottom"
            class="override_home_welcome"
          >
            <v-col
              align="end" 
              class="text-center"
              cols="12"
            >
              <v-row class="my-8">
                <v-col class="text-center">
                  <h1 class="text-h3 mb-8 text-center force-center-title">
                    Oops!
                  </h1>
                  <p class="text-h5">
                    We couldn't find the page you were looking for, where did you want to go?
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="no-parallax shorter_content">
        <footer-bar />
      </section>
    </main>
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";

export default {
  name: "NotFound",
  components: {FooterBar}
};
</script>
