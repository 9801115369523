<template>
  <v-footer
    app
    padless
    color="homebgcolour"
    class="bottom_bit"
  >
    <v-row
      justify="center"
      no-gutters
      class="py-2"
    >
      <v-btn
        text
        rounded
        to="/"
      >
        Home
      </v-btn>
      <v-btn
        text
        rounded
        to="/features"
      >
        Features
      </v-btn>
      <v-btn
        text
        rounded
        to="/pricing"
      >
        Pricing
      </v-btn>
      <v-btn
        v-if="!isLoggedIn"
        text
        rounded
        to="/register"
      >
        Get Started
      </v-btn>
      <v-btn
        text
        rounded
        to="/about"
      >
        About Us
      </v-btn>
      <v-btn
        text
        rounded
        to="/contact"
      >
        Contact Us
      </v-btn>
      <v-btn
        text
        rounded
        href="https://status.airfieldhub.com"
        target="_blank"
        title="View the status of our systems and uptime data."
      >
        <v-icon left>
          mdi-check-circle-outline
        </v-icon>
        Status
      </v-btn>

      <v-col
        class="py-4 text-center"
        cols="12"
      >
        <v-btn
          text
          x-small
          to="/terms-and-conditions"
        >
          Terms &amp; Conditions
        </v-btn>
        <v-btn
          v-if="!isLoggedIn"
          text
          x-small
          to="/terms-of-service"
        >
          Terms of Service
        </v-btn>
      </v-col>

      <v-col
        class="py-4 text-center"
        cols="12"
      >
        <p class="text-body-2 my-0 mb-1">
          &copy; {{ new Date().getFullYear() }} <b>AirfieldHub Ltd</b>.
        </p>
        <p class="text-caption my-0">
          AirfieldHub Ltd is a company registered in England and Wales with
          company number 13373459.
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>

export default {
  name: "FooterBar",

  components: {  },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth.loggedIn;
    }
  },

  methods: {
    
  }
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px;
  }
  .logo {
    max-width: 38vw;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  .logo {
    max-width: 28vw;
  }
}

.bottom_bit{
  position: absolute;
  display: block;
  width: 100%;
  height: 250px;
  padding-top: 70px;
}
</style>
