import { DateTime } from "luxon";

export default class DatesHelper {
  static formatIsoNoTimezone = "YYYY-MM-DD[T]HH:mm:ss";

  /**
   * Turns a filter type to a range of dates
   * [from] is inclusive (use >=)
   * [to] is exclusive (use <)
   *
   * @param {string} type
   * @returns {Record<String, DateTime>} Date range with inclusive [from] (use >=), and exclusive [to] (use <)
   */
  static rangeFromFilterType(type, base_date=null) {
    var today = DateTime.now()
      .toUTC()
      .startOf("day");
    //var today = DateTime.fromISO("2023-02-04T00:00").startOf("day");
    //var base_me_from = DateTime.fromISO(base_date).toFormat("yyyy-MM-dd HH:mm")
    if(base_date && base_date !== null){
      today = DateTime.fromISO(base_date).startOf("day");
      console.log("changing the date : ", today);
    } 
    // else {
    //   today = DateTime.now()
    //   .toUTC()
    //   .startOf("day");
    // }

    if (type === "last-year") {
      return {
        from: today.minus({ year: 1 }).startOf("year"),
        to: today.startOf("year")
      };
    }

    if (type === "this-year") {
      return {
        from: today.startOf("year"),
        to: today.plus({ year: 1 }).startOf("year")
      };
    }

    if (type === "last-7-days") {
      return {
        from: today.minus({ days: 1 }).minus({ days: 7 }),
        to: today
      };
    }

    if (type === "last-30-days") {
      return {
        from: today.minus({ days: 1 }).minus({ days: 30 }),
        to: today
      };
    }

    if (type === "yesterday") {
      return {
        from: today.minus({ days: 1 }),
        to: today
      };
    }

    if (type === "tomorrow") {
      return {
        from: today.plus({ days: 1 }),
        to: today.plus({ days: 1 }).plus({ days: 1 })
      };
    }

    if (type === "next-7-days") {
      return {
        from: today.plus({ days: 1 }),
        to: today.plus({ days: 7 })
      };
    }

    if (type === "next-30-days") {
      return {
        from: today.add(1, "days"),
        to: today.add(30, "days").add(1, "days")
      };
    }

    if (type === "month") {
      return {
        from: today.startOf("month"),
        to: today.endOf("year")
      };
    }

    if (type === "week") {
      return {
        from: today.startOf("week"),
        to: today.endOf("week")
      };
    }

    // today
    return {
      from: today,
      to: today.plus({ days: 1 })
    };
  }

  static filterOptions() {
    return {
      "Last Year": "last-year",
      "This Year": "this-year",
      "Last 30 days": "last-30-days",
      "Last 7 days": "last-7-days",
      Yesterday: "yesterday",
      Today: "today",
      Tomorrow: "tomorrow",
      "Next 7 days": "next-7-days",
      "Next 30 days": "next-30-days"
    };
  }

  static textFromFilterType(type) {
    switch (type) {
      case "last-year":
        return "Last Year";
      case "this-year":
        return "This Year";
      case "last-30-days":
        return "Last 30 days";
      case "last-7-days":
        return "Last 7 days";
      case "yesterday":
        return "Yesterday";
      case "tomorrow":
        return "Tomorrow";
      case "next-7-days":
        return "Next 7 days";
      case "next-30-days":
        return "Next 30 days";
      default:
        return "Today";
    }
  }
}
