import Vue from "vue";
import VueTour from "vue-tour";
import App from "./App.vue";
import { supabase } from "./supabase";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/app";

Vue.config.productionTip = false;

Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

let app = null;

async function start(session) {
  await store.dispatch("auth/setSession", session);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    });
    app.$mount("#app");
  }
}

supabase.auth.getSession().then(({ data }) => {
  start(data.session);
});
supabase.auth.onAuthStateChange((_, session) => {
  start(session);
});
