<template>
  <v-app>
    <nav-bar />
    <v-main>
      <transition 
        name="fade"
        enter-to-class="opacity0"
        leave-to-class="opacity0"
      >
        <slot />
      </transition>
    </v-main>
    <!-- <footer-bar /> -->
  </v-app>
</template>

<script>
import NavBar from "./NavBar.vue";
// import FooterBar from "./FooterBar.vue";

export default {
  name: "MarketingPage",

  components: { NavBar },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth.loggedIn;
    }
  }
};
</script>

<style lang="scss">
.theme--dark {
  .highlight {
    background-color: rgba(215, 255, 215, 0.15);
  }
}

.theme--light {
  .highlight {
    background-color: rgba(0, 30, 0, 0.135);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.features_wrapper, .home_wrapper, .other_pages{
  transition: opacity 0.5s;
}

.opacity0{
  opacity: 0;
}

</style>
