<template>
  <div class="home_wrapper">
    <main id="home_main">
      <section class="parallax bg">
        <v-container
          fill-height
          fluid
        >
          <v-row   
            vertical-align="bottom"
            class="override_home_welcome"
          >
            <v-col
              align="end" 
              class="text-center"
              cols="12"
            >
              <v-row class="my-8">
                <v-col class="text-center">
                  <h1 class="text-h3 mb-8 text-center force-center-title">
                    Effortless airfield operations!
                  </h1>
                  <p class="text-h5">
                    Manage your airfield's PPR, Booking Out, and landing fees online.
                  </p>
                  <br>
                  <v-btn
                    color="homebuttonbg"
                    class="homebuttoncolour--text"
                    x-large
                    @click="$vuetify.goTo('#section2', options)"
                    aria-label="Find out more"
                  >
                    <v-icon left>
                      mdi-rocket-launch
                    </v-icon>
                    Find out more
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section
        id="section2"
        class="no-parallax"
      >
        <v-container>
          <v-row
            justify="center"
            class="text-center"
          >
            <v-col class="col-12 col-sm-4">
              <v-icon
                size="48"
                color="homeiconcolour"
              >
                mdi-airplane-landing
              </v-icon>
              <h2 class="text-h4 mt-4 mb-8">
                Online PPR
              </h2>
              <p class="text-body-1">
                Receive PPR requests and instantly approve or reject with a simple
                click of a button.
              </p>
            </v-col>
            <v-col class="col-12 col-sm-4">
              <v-icon
                size="48"
                color="homeiconcolour"
              >
                mdi-airplane-takeoff
              </v-icon>
              <h2 class="text-h4  mt-4 mb-8">
                Online Book-Out
              </h2>
              <p class="text-body-1 ">
                Collect everything you need for your airfield logs without any
                illegible handwritten squiggles.
              </p>
            </v-col>
            <v-col class="col-12 col-sm-4">
              <v-icon
                size="48"
                color="homeiconcolour"
              >
                mdi-contactless-payment-circle
              </v-icon>
              <h2 class="text-h4  mt-4 mb-8">
                Fees &amp; Charges
              </h2>
              <p class="text-body-1 ">
                Go cashless, with online card payments for all your landing fees and overnight parking fees.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex flex-column justify-space-between align-center my-8">
                <v-btn
                  color="homebuttonbg"
                  class="homebuttoncolour--text"
                  x-large
                  @click="$vuetify.goTo('#section4', options)"
                >
                  <v-icon left>
                    mdi-rocket-launch
                  </v-icon>
                  WHY USE AIRFIELDHUB
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section
        id="section3"
        class="parallax bg2"
      >
        <div class="pb-16">
          <div class="my-16">
            <v-container>
              <div class="d-flex flex-column justify-space-between align-center my-8">
                <v-btn
                  color="homebuttonbg"
                  class="homebuttoncolour--text"
                  x-large
                  to="/register"
                >
                  <v-icon left>
                    mdi-rocket-launch
                  </v-icon>
                  Try AirfieldHub Today
                </v-btn>
              </div>
            </v-container>
          </div>
        </div>
      </section>

      <section
        id="section4"
        class="no-parallax longer_content"
      >
        <div class="pb-16">
          <v-container>
            <v-row>
              <v-col>
                <h3 class="text-h4  mb-8">
                  Why use AirfieldHub?
                </h3>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              class="text-center"
            >
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-face-mask
                </v-icon>
                <h2 class="text-h5 my-4">
                  Contactless
                </h2>
                <p class="text-body-1">
                  Operate entirely online, accepting PPR, paying landing fees, and
                  booking out, all online and contact-free for speed and safety.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-email-fast
                </v-icon>
                <h2 class="text-h5 my-4">
                  Ease of Email
                </h2>
                <p class="text-body-1">
                  No complicated sign-up or payment methods required, pilots simply
                  use their email address for hassle free PPR, and booking out.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-numeric
                </v-icon>
                <h2 class="text-h5 my-4">
                  Simplicity
                </h2>
                <p class="text-body-1">
                  Built from the ground up to be simple to use, managing your airfield
                  operations and taking online payments with ease.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-monitor-dashboard
                </v-icon>
                <h2 class="text-h5 my-4">
                  Dashboard
                </h2>
                <p class="text-body-1 ">
                  Powerful yet simple dashboard with all of today's arrivals and
                  departures updating real-time. Approve, reject, mark as arrived or
                  departed with a single click.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-code-tags
                </v-icon>
                <h2 class="text-h5 my-4">
                  Website Widgets
                </h2>
                <p class="text-body-1">
                  Add PPR and Book Out forms to your website with our drop-in widgets,
                  and start collecting PPR and booking outs online with zero fuss.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-link-variant
                </v-icon>
                <h2 class="text-h5 my-4">
                  Payment Links
                </h2>
                <p class="text-body-1">
                  Payment links and QR codes to take pilots straight to a payment
                  screen, for unattended airfields or reminders around the club house.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-code-tags
                </v-icon>
                <h2 class="text-h5 my-4">
                  WordPress Integration
                </h2>
                <p class="text-body-1">
                  Add PPR and Book Out forms to your website with our drop-in widgets,
                  and start collecting PPR and BOOKOUTS online with zero fuss. Just drag and drop the forms from our custom WordPress plugin!
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-code-tags
                </v-icon>
                <h2 class="text-h5 my-4">
                  ReactJS Library
                </h2>
                <p class="text-body-1">
                  With as few as 4 lines of code copy pasted from our documentation, get your PPR and BOOKOUT forms within your ReactJS apps and websites.
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-code-tags
                </v-icon>
                <h2 class="text-h5 my-4">
                  Angular 14+ Library
                </h2>
                <p class="text-body-1">
                  With as few as 8 lines of code copy pasted from our documentation, get your PPR and BOOKOUT forms within your Angular 14+ apps and websites.
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3 class="text-h4 my-8">
                  Coming soon...
                </h3>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              class="text-center"
            >
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-code-tags-check
                </v-icon>
                <h2 class="text-h5 my-4">
                  More Website Integrations
                </h2>
                <p class="text-body-1 ">
                  Integrate our widgets with just a few clicks in your:
                  Squarespace, Wix, and more...
                </p>
              </v-col>
              <v-col class="col-12 col-sm-4">
                <v-icon
                  size="48"
                  color="homeiconcolour"
                >
                  mdi-dots-horizontal
                </v-icon>
                <h2 class="text-h5 my-4">
                  Much more...
                </h2>
                <p class="text-body-1">
                  So much more coming soon, if you want to know more get in touch, or
                  sign up today and we'll let you know as soon as we can.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>

      <section class="parallax bg3">
        <div class="pb-16">
          <div class="my-16">
            <v-container>
              <div class="d-flex flex-column justify-space-between align-center my-8">
                <p class="text-h6">
                  Sign up today for free and manage your airfield like a rock star!
                </p>
                <v-btn
                  color="homebuttonbg"
                  class="homebuttoncolour--text"
                  x-large
                  to="/register"
                >
                  <v-icon left>
                    mdi-rocket-launch
                  </v-icon>
                  Get Started today
                </v-btn>
              </div>
            </v-container>
          </div>
        </div>
      </section>

      <section class="no-parallax shorter_content">
        <footer-bar />
      </section>
    </main>
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";
export default {
  name: "Home",
  components: { FooterBar },
  computed: {
    options () {
        return {
          duration: 600,
          offset: 100,
          easing: "easeInOutCubic",
          container: "#home_main"
        }
      },
  }
};
</script>

<style lang="scss">
.theme--dark {
  .bottom-gradient {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.4) 0%,
      transparent 100%
    );
  }
}

.theme--light {
  .bottom-gradient {
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.25) 0%,
      transparent 100%
    );
  }
}

.home_wrapper{
  height: auto;
  width: 100%;
  overflow: hidden;
  display: block;
}

.home_parallax{
  height: 100vh;
  min-height: 90vh;
  top:0;
}

.v-parallax__image{
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
  min-height: auto !important;
  background-size: 100%;
  top:0;
}

.background_image_wrapper{
  width: 100%;
  height: 100%;

  .background_image{
    width: 100%;
  }

}

main {
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
  display: block;
  clear: both;
  margin-bottom: 0;
  padding-bottom: 0;
}

section {
  transform-style: preserve-3d;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-parallax {
  background-color: var(--v-homebgcolour-base);
  color: var(--v-homebgtextcolour-base);
  z-index: 999;
}

.parallax{
    color: var(--v-homebgparallaxtextcolour-base);
}

section h1 {
  text-align: center;
  font-size: 4rem;
  font-family: sans-serif;
}

.parallax h1 {
  width: 60%;
  font-size: 2rem;
}

.parallax::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size: 100%;
  z-index: -1;
}

.longer_content{
  height: auto !important;
  min-height: 100vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.shorter_content{
  height: 250px;
}
.force-center-title{
  margin: 0 auto;
}
.override_home_welcome{
  position: absolute;
  bottom: 50px;
  width: 100%;
}
#section2{
  height: 50vh;
  min-height: 400px;
}
.smaller_section_intro{
  height: 75vh;
}

.smaller_no_parallax_content{
  height: auto;
  min-height: 400px;
}

.no_webp{
  .bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/farnborough_runway2.jpg") center;
    background-size: cover;
  }
  .bg2::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/biggin_hill.jpg") center;
    background-size: cover;
  }
  .bg3::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/turbs2.jpg") center;
    background-size: cover;
  }
  .feature_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/beech_formation.jpg") top;
    background-size: cover;
  }
  .feature_bg2::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/cannes_smaller.jpg") center;
    background-size: cover;
  }
  .feature_bg3::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/guernsey.jpg") bottom;
    background-size: cover;
  }
  .feature_bg4::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/white_waltham_2_smaller.jpg") bottom;
    background-size: cover;
  }
  .pricing_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/al_ula.jpg") bottom;
    background-size: cover;
  }
  .pricing_bg2::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/guernsey.jpg") bottom;
    background-size: cover;
  }
  .pricing_bg3::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/white_waltham_2_smaller.jpg") bottom;
    background-size: cover;
  }
  .not_found_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/map.jpg") bottom;
    background-size: cover;
  }
  .tos_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/lfat_approach2.jpg") bottom;
    background-size: cover;
  }
  .tnc_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/lfat_mooney2.jpg") bottom;
    background-size: cover;
  }
}

.webp{
  .bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/farnborough_runway2.webp") center;
    background-size: cover;
  }
  .bg2::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/biggin_hill.webp") center;
    background-size: cover;
  }
  .bg3::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/turbs2.webp") center;
    background-size: cover;
  }
  .feature_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/beech_formation.webp") top;
    background-size: cover;
  }
  .feature_bg2::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/cannes_smaller.webp") center;
    background-size: cover;
  }
  .feature_bg3::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/guernsey.webp") bottom;
    background-size: cover;
  }
  .feature_bg4::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/white_waltham_2_smaller.webp") bottom;
    background-size: cover;
  }
  .pricing_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/al_ula.webp") bottom;
    background-size: cover;
  }
  .pricing_bg2::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/guernsey.webp") bottom;
    background-size: cover;
  }
  .pricing_bg3::after {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("~@/assets/images/white_waltham_2_smaller.webp") bottom;
    background-size: cover;
  }
  .not_found_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/map.webp") bottom;
    background-size: cover;
  }
  .tos_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/lfat_approach2.webp") bottom;
    background-size: cover;
  }
  .tnc_bg::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
      url("~@/assets/images/lfat_mooney2.webp") bottom;
    background-size: cover;
  }
}

.full_height{
  min-height: calc( 100vh - 250px );
}

</style>
