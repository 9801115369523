<template>
  <v-container class="other_pages">
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
      >
        <h2 class="text-h4 my-4">
          Create My Airfield
        </h2>
        <validation-observer ref="observer">
          <v-form
            ref="form"
            lazy-validation
            :disabled="isSubmitting"
            @submit.prevent="sendLink"
          >
            <v-card>
              <v-card-text>
                <p
                  v-if="showLinkSent"
                  class="text-body-1"
                >
                  We've sent you a magic link to <b>{{ email }}</b>, please click the link in your email to get started.
                </p>
                <p
                  v-if="!showLinkSent"
                  class="text-body-1"
                >
                  To create your airfield we just need your email address, and
                  we'll send you a magic link to continue.
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-if="!showLinkSent"
                    label="Email Address"
                    type="email"
                    autocomplete="email"
                    required
                    v-model="email"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!showLinkSent"
                  color="homebuttonbg"
                  class="homebuttoncolour--text mb-4"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Create My Airfield
                </v-btn>
                <v-spacer />
              </v-card-actions>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!showLinkSent"
                  text
                  small
                  color="homebuttonbg"
                  class="homebuttoncolour--text"
                  to="/login"
                >
                  Already have an account?
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {supabase} from "../supabase";
import {email, required} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";

const _getEmail = function () {
  return window.localStorage.getItem("emailForLogin");
};
const _setEmail = function (email) {
  return window.localStorage.setItem("emailForLogin", email);
};

const _doLogin = async loginData => {
  try {
    const {error} = await supabase.functions.invoke("authentication-loginLink", {
      body: {
        url: window.location.origin + "/magicLinkLogin",
        email: loginData.email,
      }
    });

    if (error) console.error(error);

    _setEmail(loginData.email);
    loginData.showLinkSent = true;
  } catch (error) {
    console.error(error);
  }
};

export default {
  name: "Register",
  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Please enter an email address"
    });

    extend("email", {
      ...email,
      message: "Email must be valid"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    isSubmitting: false,
    email: _getEmail() || "",
    showLinkSent: false
  }),

  methods: {
    sendLink: function () {
      this.isSubmitting = true;
      let data = this;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            await _doLogin(data);
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
