import Vue from "vue";
import VueRouter from "vue-router";
import { supabase } from "../supabase";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import MagicLinkLogin from "../views/MagicLinkLogin.vue";
import NotFound from "../views/NotFound.vue";
import Register from "../views/Register.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import TermsOfService from "../views/TermsOfService.vue";

Vue.use(VueRouter);

const metaTitle = "AirfieldHub";
const metaTitleAdmin = `Admin - ${metaTitle}`;

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: metaTitle, transition: "slide-left" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: `Login - ${metaTitle}` },
  },
  {
    path: "/magicLinkLogin",
    name: "Magic Link Login",
    component: MagicLinkLogin,
    meta: { title: `Logging In - ${metaTitle}` },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { title: `Register - ${metaTitle}` },
  },
  {
    path: "/features",
    name: "Features",
    meta: { title: `Features - ${metaTitle}` },
    component: () =>
      import(/* webpackChunkName: "features" */ "../views/Features.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    meta: { title: `Pricing - ${metaTitle}` },
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
  },
  {
    path: "/about",
    name: "About Us",
    meta: { title: `About Us - ${metaTitle}` },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: TermsAndConditions,
    meta: { title: `Terms & Conditions - ${metaTitle}` },
  },
  {
    path: "/terms-of-service",
    name: "Terms and Service",
    component: TermsOfService,
    meta: { title: `Terms of Service - ${metaTitle}` },
  },
  {
    path: "/contact",
    name: "Contact Us",
    meta: { title: `Contact Us - ${metaTitle}` },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/link/arrival/cancel",
    name: "Email Link Arrival Cancel",
    meta: { title: `Cancel Arrival - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkArrival" */ "../views/link/arrival/Cancel.vue"
      ),
    props: (route) => ({
      id: route.query.id,
      email: route.query.email,
    }),
  },
  {
    path: "/link/arrival/approve",
    name: "Email Link Arrival Approve",
    meta: { title: `Approve Arrival - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkArrival" */ "../views/link/arrival/Approve.vue"
      ),
    props: (route) => ({
      id: route.query.id,
      email: route.query.email,
    }),
  },
  {
    path: "/link/arrival/reject",
    name: "Email Link Arrival Reject",
    meta: { title: `Reject Arrival - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkArrival" */ "../views/link/arrival/Reject.vue"
      ),
    props: (route) => ({
      id: route.query.id,
      email: route.query.email,
    }),
  },
  {
    path: "/link/departure/cancel",
    name: "Email Link Departure Cancel",
    meta: { title: `Cancel Departure - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkDeparture" */ "../views/link/departure/Cancel.vue"
      ),
    props: (route) => ({
      id: route.query.id,
      email: route.query.email,
    }),
  },
  {
    path: "/link/departure/new/:payload/",
    name: "Email Link Departure Form",
    meta: { title: `Book Out - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkDeparture" */ "../views/link/departure/Form.vue"
      ),
    props: (route) => ({
      payload: route.params.payload,
    }),
  },
  {
    path: "/link/payment/landing-fee/success/:payload/",
    name: "Landing Fee Payment Success",
    meta: { title: `Payment Success - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkPaymentLandingFee" */ "../views/link/payment/LandingFeeSuccess.vue"
      ),
    props: (route) => ({
      payload: route.params.payload,
    }),
  },
  {
    path: "/link/payment/landing-fee/:payload/",
    name: "Landing Fee Payment",
    meta: { title: `Landing Fee Payment - ${metaTitle}` },
    component: () =>
      import(
        /* webpackChunkName: "linkPaymentLandingFee" */ "../views/link/payment/LandingFee.vue"
      ),
    props: (route) => ({
      payload: route.params.payload,
    }),
  },
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    meta: {
      title: metaTitleAdmin,
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "adminDashboard" */ "../views/admin/Dashboard.vue"
          ),
        meta: {
          title: `Dashboard - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "tower",
        name: "Tower",
        component: () =>
          import(
            /* webpackChunkName: "adminTower" */ "../views/admin/Tower.vue"
          ),
        meta: {
          title: `Tower - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/new",
        name: "New Airfield",
        component: () =>
          import(
            /* webpackChunkName: "adminAirfieldEdit" */ "../views/admin/airfield/New.vue"
          ),
        meta: {
          title: `New Airfield - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/edit",
        name: "Edit Airfield",
        component: () =>
          import(
            /* webpackChunkName: "adminAirfieldEdit" */ "../views/admin/airfield/Edit.vue"
          ),
        meta: {
          title: `Edit Airfield - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/setup-payments",
        name: "Setup Payments",
        component: () =>
          import(
            /* webpackChunkName: "adminAirfieldPayments" */ "../views/admin/airfield/SetupPayments.vue"
          ),
        meta: {
          title: `Setup Payments - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/complete-payments-setup",
        name: "Complete Payments Setup",
        component: () =>
          import(
            /* webpackChunkName: "adminAirfieldPayments" */ "../views/admin/airfield/CompletePaymentsSetup.vue"
          ),
        meta: {
          title: `Complete Payments Setup - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/fees-and-charges",
        name: "Fees & Charges",
        component: () =>
          import(
            /* webpackChunkName: "adminFeesAndCharges" */ "../views/admin/airfield/FeesAndCharges.vue"
          ),
        meta: {
          title: `Fees & Charges - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/fees-and-charges/landing-fees/edit",
        name: "Landing Fees Setup",
        component: () =>
          import(
            /* webpackChunkName: "adminFeesAndCharges" */ "../views/admin/airfield/landingFees/Edit.vue"
          ),
        meta: {
          title: `Landing Fee Setup - Fees & Charges - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/fees-and-charges/parking-fees/edit",
        name: "Overnight Parking Fees Setup",
        component: () =>
          import(
            /* webpackChunkName: "adminFeesAndCharges" */ "../views/admin/airfield/parkingFees/Edit.vue"
          ),
        meta: {
          title: `Overnight Parking Fee Setup - Fees & Charges - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/fees-and-charges/printable-sign",
        name: "Landing Fees Printable Sign",
        component: () =>
          import(
            /* webpackChunkName: "adminFeesAndCharges" */ "../views/admin/airfield/landingFees/PrintableSign.vue"
          ),
        meta: {
          title: `Printable Sign - Fees & Charges - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "airfield/calendar",
        name: "Airfield Calendar",
        component: () =>
          import(
            /* webpackChunkName: "adminFeesAndCharges" */ "../views/admin/calendar/Calendar.vue"
          ),
        meta: {
          title: `Airfield Calendar - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
      },
      {
        path: "arrivals",
        name: "Arrivals",
        component: () =>
          import(
            /* webpackChunkName: "adminArrivals" */ "../views/admin/Arrivals.vue"
          ),
        meta: {
          title: `Arrivals - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
        children: [
          {
            path: "list",
            name: "Arrivals List",
            component: () =>
              import(
                /* webpackChunkName: "adminArrivalsList" */ "../views/admin/arrivals/List.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "new",
            name: "New Arrival",
            component: () =>
              import(
                /* webpackChunkName: "adminArrivalsNew" */ "../views/admin/arrivals/New.vue"
              ),
            meta: {
              title: `New Arrival - ${metaTitleAdmin}`,
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "departures",
        name: "Departures",
        component: () =>
          import(
            /* webpackChunkName: "adminDepartures" */ "../views/admin/Departures.vue"
          ),
        meta: {
          title: `Departures - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
        children: [
          {
            path: "list",
            name: "Departures List",
            component: () =>
              import(
                /* webpackChunkName: "adminDeparturesList" */ "../views/admin/departures/List.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "new",
            name: "New Departure",
            component: () =>
              import(
                /* webpackChunkName: "adminDeparturesNew" */ "../views/admin/departures/New.vue"
              ),
            meta: {
              title: `New Departure - ${metaTitleAdmin}`,
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "settings",
        name: "Settings",
        component: () =>
          import(
            /* webpackChunkName: "adminSettings" */ "../views/admin/Settings.vue"
          ),
        meta: {
          title: `Settings - ${metaTitleAdmin}`,
          requiresAuth: true,
        },
        children: [
          {
            path: "widgets",
            name: "Widgets",
            component: () =>
              import(
                /* webpackChunkName: "adminSettingsWidgets" */ "../views/admin/settings/Widgets.vue"
              ),
            meta: {
              title: `Widgets - ${metaTitleAdmin}`,
              requiresAuth: true,
            },
          },
          {
            path: "config",
            name: "Config",
            component: () =>
              import(
                /* webpackChunkName: "adminSettingsConfig" */ "../views/admin/settings/Config.vue"
              ),
            meta: {
              title: `Config - ${metaTitleAdmin}`,
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: `Page Not Found - ${metaTitle}`,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await supabase.auth.getSession();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const goingToLogin = to.path === "/login";

  if (requiresAuth && !currentUser && !goingToLogin) {
    next("/login");
  } else next();
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
