import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import arrivals from "./arrivals";
import auth from "./auth";
import departures from "./departures";
import calendar from "./calendar";
import theme from "./theme";
import tower from "./tower";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {},
  getters: {},
  mutations: {
    ...vuexfireMutations,
  },
  actions: {},
  modules: {
    theme,
    auth,
    arrivals,
    departures,
    calendar,
    tower,
  },
});
