import vuetify from "../plugins/vuetify";

const theme = {
  namespaced: false,
  strict: process.env.NODE_ENV !== "production",
  state: {
    theme: {}
  },
  getters: {
    getTheme: state => state.theme
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.theme = theme;
    }
  },
  actions: {
    initTheme({ commit }) {
      const cachedTheme = localStorage.theme ? localStorage.theme : false;
      // `true` if the user has set theme to `dark` on browser/OS
      const userPrefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;

      if (cachedTheme) {
        commit("setTheme", cachedTheme);
        vuetify.framework.theme.dark = cachedTheme === "dark";
      } else if (userPrefersDark) {
        commit("setTheme", "dark");
        vuetify.framework.theme.dark = true;
      } else {
        commit("setTheme", "light");
        vuetify.framework.theme.dark = false;
      }
    },
    toggleTheme({ commit }) {
      switch (localStorage.theme) {
        case "light":
          commit("setTheme", "dark");
          vuetify.framework.theme.dark = true;
          break;

        default:
          commit("setTheme", "light");
          vuetify.framework.theme.dark = false;
          break;
      }
    }
  }
};

export default theme;
