<template>
  <v-app>
    <v-app-bar
      app
      color="navbackground"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon
        color="navcolour"
        @click="drawer = !drawer"
      />
      <v-btn
        text
        rounded
        color="navbackground"
        to="/admin/dashboard"
      >
        <div class="logo2" />
      </v-btn>

      <v-spacer />

      <v-toolbar-items>
        <v-menu
          left
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              color="navcolour"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-circle</v-icon>
              <div class="text-left px-3 d-none d-md-inline-block">
                <div class="text-body-1 font-weight-light mb-n1" style="text-transform: none;">{{ userName }}</div>
                <div class="text-caption font-weight-regular" style="text-transform: none;">{{ airfieldName }}</div>
              </div>
              <v-icon small>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <user-menu />
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      :value="$vuetify.breakpoint.mobile ? drawer : true"
      :mini-variant="$vuetify.breakpoint.mobile ? false : drawer"
      app
      clipped
    >
      <v-list
        nav
        dense
      >
        <v-list-item
          link
          to="/admin/dashboard"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/admin/tower"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-radio-tower</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tower</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/admin/arrivals/list"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-airplane-landing</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Arrivals</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/admin/departures/list"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-airplane-takeoff</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Departures</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="landingFeesUrl"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Fees &amp; Charges</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/admin/airfield/calendar"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Calendar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list
        nav
        dense
      >
        <v-list-item
          id="settingsLink"
          link
          to="/admin/settings/config"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-airplane-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          id="widgetsLink"
          link
          to="/admin/settings/widgets"
          :disabled="!hasRequiredData"
        >
          <v-list-item-icon>
            <v-icon>mdi-code-tags</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Widgets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="surface">
      <v-container fluid>
        <slot />
        <div class="bottom_space" />
      </v-container>
    </v-main>
    <v-footer
      app
      inset
      absolute
      padless
      elevation="4"
    >
      <v-row
        justify="center"
        no-gutters
        class="py-2"
      >
        <v-btn
          text
          rounded
          to="/"
        >
          Home
        </v-btn>
        <v-btn
          text
          rounded
          href="https://status.airfieldhub.com"
          target="_blank"
          title="View the status of our systems and uptime data."
        >
          <v-icon left>
            mdi-check-circle-outline
          </v-icon>
          Status
        </v-btn>

        <v-col
          class="pt-4 pb-2 text-center"
          cols="12"
        >
          <p class="text-body-2 my-0">
            &copy; {{ new Date().getFullYear() }} <b>AirfieldHub Ltd</b>.
          </p>
        </v-col>
      </v-row>
    </v-footer>

    <v-tour
      v-if="showOnboarding"
      name="onboardingTour"
      :steps="onboardingSteps"
      :callbacks="onboardingCallbacks"
    />
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMenu from "./UserMenu.vue";

export default {
  name: "AdminPage",

  components: {
    UserMenu,
  },

  mounted() {
    if (this.showOnboarding) {
      this.$tours["onboardingTour"].start();
    }
  },

  data() {
    return {
      drawer: false,
      onboardingSteps: [
        {
          target: "#arrivalsCard",
          content: `When pilots submit a PPR request for today, or you add one using the <b>+</b> button, they will show here.`,
          params: {highlight: true}
        },
        {
          target: "#departuresCard",
          content: `When pilots Books Out for today, or you add one using the <b>+</b> button, they will show here.`,
          params: {highlight: true}
        },
        {
          target: "#widgetInstallCard",
          content: `Add the AirfieldHub widgets to your website to start capturing PPR and Book Out requests, just copy/paste a tiny snippet of code and we'll do the rest.`,
          params: {highlight: true}
        },
        {
          target: "#landingFeesCard",
          content: `Sign up to accept online landing fees to make PPR and landing fee payments by pilots a breeze.`,
          params: {highlight: true}
        },
        {
          target: "#darkMode",
          content: `Whether you prefer a darker style or our light mode we have both options, just toggle modes here!`,
          params: {highlight: true}
        }
      ],
      onboardingCallbacks: {
        onSkip: this.onOnboardingComplete,
        onFinish: this.onOnboardingComplete
      }
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      hasRequiredData: "auth/hasRequiredData",
      showOnboarding: "auth/showOnboarding",
      isStripeOnboardingComplete: "auth/airfieldStripeOnboardingComplete",
      userName: "auth/userName",
      airfieldName: "auth/airfieldName",
    }),
    landingFeesUrl() {
      if (!this.isStripeOnboardingComplete) {
        return "/admin/airfield/setup-payments";
      }
      return "/admin/airfield/fees-and-charges";
    },
  },

  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),
    async onOnboardingComplete() {
      await this.$store.dispatch("auth/updateUserOnboardingViewed");
    }
  }
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px;
  }
  .logo {
    max-width: 38vw;
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} {
  .logo {
    max-width: 28vw;
  }
}

.theme--light .surface {
  background-color: rgba(180, 180, 200, 0.22);
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.65) !important;
}

.logo2 {
  width: 240px;
  height: 35px;
  max-width: 44vw;
  background-color: var(--v-navcolour-base);
  -webkit-mask: url('~@/assets/logo.svg') no-repeat center;
  mask: url('~@/assets/logo.svg') no-repeat center;
}

.bottom_space {
  position: relative;
  display: block;
  height: 100px;
  width: 100%;
}
</style>
