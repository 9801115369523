import {supabase} from "../supabase";
import router from "../router";

let airfieldChannel;
export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state: {
    loggedIn: false,
    userData: null,
    userDocument: null,
    userAirfields: [],
    airfieldDocument: null
  },
  getters: {
    isLoggedIn(state) {
      return state.loggedIn;
    },
    userId(state) {
      return state.userDocument?.id;
    },
    userName(state) {
      return state.userDocument?.name;
    },
    userEmail(state) {
      return state.userDocument?.email;
    },
    airfields(state) {
      return state.userAirfields;
    },
    airfieldId(state) {
      return state.airfieldDocument?.id;
    },
    airfieldName(state) {
      return state.airfieldDocument?.name || "";
    },
    airfieldIcaoCode(state) {
      return state.airfieldDocument?.icao_code;
    },
    airfieldWebsiteUrl(state) {
      return state.airfieldDocument?.website_url || null;
    },
    airfieldPilotBriefingUrl(state) {
      return state.airfieldDocument?.pilot_briefing_url || null;
    },
    hasRequiredAirfieldData(state) {
      const data = state.airfieldDocument;
      return data !== null;
    },
    hasRequiredOwnerData(state) {
      return typeof state.userDocument?.name === "string";
    },
    hasRequiredData(_, getters) {
      return getters.hasRequiredAirfieldData && getters.hasRequiredOwnerData;
    },

    showOnboarding(state, getters) {
      return (
        getters.hasRequiredData && state.userDocument?.onboarding_viewed !== true
      );
    },

    showWidgetInstallPrompt(state, getters) {
      return (
        getters.hasRequiredData &&
        state.userDocument?.hide_widget_install !== true
      );
    },

    showLandingFeesPrompt(state, getters) {
      return (
        getters.hasRequiredData &&
        state.userDocument?.hide_landing_fees !== true &&
        !getters.airfieldStripeOnboardingComplete
      );
    },

    airfieldHasStripeAccount: state => {
      return state.airfieldDocument?.has_stripe_account || false;
    },
    airfieldStripeOnboardingComplete: state => {
      return state.airfieldDocument?.stripe_onboarding_complete || false;
    },
    airfieldStripeChargesEnabled: state => {
      return state.airfieldDocument?.stripe_charges_enabled || false;
    },

    landingFeesConfig: state => {
      return state.airfieldDocument?.landing_fees_config || {};
    },
    parkingFeesConfig: state => {
      return state.airfieldDocument?.parking_fees_config || {};
    },
    operationMode: state => {
      return state.airfieldDocument?.operation_mode || "attended";
    },
    runwayConfig: state => {
      return state.airfieldDocument?.runway_config || {
        active: {},
        runways: []
      };
    },
    weatherConfig: state => {
      return state.airfieldDocument?.weather_config || {
        icaoCode: state.airfieldDocument?.icaoCode?.toUpperCase() || "",
        airfieldElevation: null,
      };
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.loggedIn = data !== null;
      state.userData = data;
    },
    SET_USER_DOCUMENT(state, data) {
      state.userDocument = data;
    },
    SET_USER_AIRFIELDS(state, data) {
      state.userAirfields = data;
    },
    SET_AIRFIELD_DOCUMENT(state, data) {
      //console.log("set airfield. ", data);
      state.airfieldDocument = data;
      localStorage.airfield = JSON.stringify(data);
    },
  },
  actions: {
    setSession: async ({commit, dispatch}, sessionData) => {
      if (sessionData) {
        const userData = {
          id: sessionData.user.id,
          email: sessionData.user.email
        };
        await commit("SET_USER", userData);
        await dispatch("fetchUserDocument", userData);
      } else {
        // logged out
        await commit("SET_AIRFIELD_DOCUMENT", null);
        await commit("SET_USER_DOCUMENT", null);
        await commit("SET_USER_AIRFIELD", null);
        await commit("SET_USER_AIRFIELDS", []);
        await commit("SET_USER", null);
      }
    },

    fetchUserDocument: async ({commit, dispatch}, userData) => {
      const {data, error} = await supabase.from('user')
        .select()
        .eq('id', userData.id)
        .limit(1).maybeSingle()

      if (error) {
        return console.error(error);
      }
      if (!data) return;

      await commit("SET_USER_DOCUMENT", data);
      await dispatch("fetchUserAirfields");
      await dispatch("subscribeAirfield");
    },

    fetchUserAirfields: async ({commit}) => {
      const {data, error} = await supabase.functions.invoke("authentication-airfields");

      if (error) {
        return console.error(error);
      }
      if (!data) return;

      await commit("SET_USER_AIRFIELDS", data);
    },

    updateUser: async ({state, commit}, newData) => {
      const {data, error} = await supabase.functions.invoke("airfields-register", {
        body: {
          type: "user",
          data: {
            id: state.userData.id,
            email: state.userData.email,
            name: newData.name,
          },
        },
      });

      if (error) {
        return console.error(error);
      }

      //console.log(data);

      commit("SET_USER_DOCUMENT", data);
    },

    updateUserOnboardingViewed: async ({state, commit}) => {
      const {data, error} = await supabase.from('user')
        .update({onboarding_viewed: true})
        .eq('id', state.userDocument.id)
        .select().single();

      if (error) {
        return console.error(error);
      }

      commit("SET_USER_DOCUMENT", data);
    },

    updateUserHideWidgetInstallPrompt: async ({state, commit}) => {
      const {data, error} = await supabase.from('user')
        .update({hide_widget_install: true})
        .eq('id', state.userDocument.id)
        .select().single();

      if (error) {
        return console.error(error);
      }

      commit("SET_USER_DOCUMENT", data);
    },

    updateUserHideLandingFeesPrompt: async ({state, commit}) => {
      const {data, error} = await supabase.from('user')
        .update({hide_landing_fees: true})
        .eq('id', state.userDocument.id)
        .select().single();

      if (error) {
        return console.error(error);
      }

      commit("SET_USER_DOCUMENT", data);
    },

    updateAirfield: async ({state, commit}, newData) => {
      const isNew = state.airfieldDocument === null;

      if (isNew) {
        const {data, error} = await supabase.functions.invoke("airfields-register", {
          body: {
            type: "airfield",
            airfieldData: newData,
            userId: state.userDocument.id,
          },
        });

        if (error) {
          return console.error(error);
        }

        commit("SET_AIRFIELD_DOCUMENT", data);
      } else {
        const {data, error} = await supabase.from('airfield')
          .update(newData)
          .eq('id', state.airfieldDocument.id)
          .select().single();

        if (error) {
          return console.error(error);
        }

        commit("SET_AIRFIELD_DOCUMENT", data);
      }
    },

    subscribeAirfield: async ({rootGetters, dispatch}) => {
      if (airfieldChannel) dispatch("unsubscribeAirfield");

      let airfieldId = rootGetters["auth/airfieldId"];
      if (!airfieldId) {
        const userAirfields = rootGetters["auth/airfields"];
        // if first load - the airfield won't be set to anything...
        //console.log("hello --> localStorage : ", localStorage);
        if (localStorage?.airfield && localStorage.airfield !== "null" && localStorage.airfield !== "[]" && localStorage.airfield !== undefined && localStorage.airfield !== "undefined" && userAirfields?.length > 0) {
          // then we have soemthing already set in the localStorage yay!
          // let us check that the airfield is in the currently obtained list of airfields.... in case there are any weird shit going on...
          //console.log("localstorage is : ", localStorage.airfield);
          let locally = JSON.parse(localStorage.airfield);
          locally = (Array.isArray(locally)) ? locally[0] : locally;
          //console.log("ID to be found: ", locally.id);
          //console.log("ID to be found IN: ", data);
          //console.log("all the airfields for user? ", data.filter(airfield => airfield.id === locally.id)[0] );
          if (userAirfields.filter(airfield => airfield.id === locally.id).length > 0) {
            const localData = userAirfields.filter(airfield => airfield.id === locally.id)[0];
            //console.log("Airfield Doc about to be set:", localData);
            airfieldId = localData.id;
          } else {
            airfieldId = userAirfields[0].id;
          }
        } else if (userAirfields && userAirfields.length > 0) {
          console.log("Airfield Doc about to be set:", userAirfields[0]);
          airfieldId = userAirfields[0].id;
        }
      }
      //console.log("which airfield we on?", airfieldId);

      const handleUpdates = function (update) {
        console.debug("Realtime update:", update);
        dispatch("fetchAirfieldDocument", airfieldId);
      };

      airfieldChannel = supabase
        .channel('airfield-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'airfield',
            filter: `id=eq.${airfieldId}`,
          },
          (payload) => handleUpdates(payload)
        )
        .subscribe()

      dispatch("fetchAirfieldDocument", airfieldId);
    },
    unsubscribeAirfield: async () => {
      await supabase.removeChannel(airfieldChannel);
    },

    fetchAirfieldDocument: async ({commit}, id) => {
      const {data, error} = await supabase.from('airfield')
        .select()
        .eq('id', id)
        .limit(1).maybeSingle();

      //console.log("is it the data that is missing? ", error);
      //console.log("ID now about to query the thingy response:: ", data);

      if (error) {
        return console.error(error);
      }
      //console.log("clearly we get here... bitches");

      if (data) {
        //console.log("to be set is : ", data);
        await commit("SET_AIRFIELD_DOCUMENT", data);
      }
    },

    switchAirfield: async ({commit}, id) => {
      const {data, error} = await supabase.from('airfield')
        .select()
        .eq('id', id)
        .limit(1).maybeSingle();

      if (error) {
        return console.error(error);
      }
      await commit("SET_AIRFIELD_DOCUMENT", data);
      router.replace("/admin/dashboard").catch(() => {
      });
    },

    async saveLandingFeesConfig({getters, commit}, newData) {
      const {data, error} = await supabase.from('airfield')
        .update({landing_fees_config: newData})
        .eq('id', getters.airfieldId)
        .select().single();

      if (error) {
        return console.error(error);
      }

      await commit("SET_AIRFIELD_DOCUMENT", data);
    },
    async saveParkingFeesConfig({getters, commit}, newData) {
      const {data, error} = await supabase.from('airfield')
        .update({parking_fees_config: newData})
        .eq('id', getters.airfieldId)
        .select().maybeSingle();

      if (error) {
        return console.error(error);
      }

      await commit("SET_AIRFIELD_DOCUMENT", data);
    },

    async saveOperationMode({getters, commit}, newData) {
      const {data, error} = await supabase.from('airfield')
        .update({operation_mode: newData})
        .eq('id', getters.airfieldId)
        .select().maybeSingle();

      if (error) {
        return console.error(error);
      }

      await commit("SET_AIRFIELD_DOCUMENT", data);
    },

    async saveRunwayConfig({getters, commit}, newData) {
      const {data, error} = await supabase.from('airfield')
        .update({runway_config: newData})
        .eq('id', getters.airfieldId)
        .select().maybeSingle();

      if (error) {
        return console.error(error);
      }

      await commit("SET_AIRFIELD_DOCUMENT", data);
    },

    async saveWeatherConfig({getters, commit}, newData) {
      const {data, error} = await supabase.from('airfield')
        .update({weather_config: newData})
        .eq('id', getters.airfieldId)
        .select().maybeSingle();

      if (error) {
        return console.error(error);
      }

      await commit("SET_AIRFIELD_DOCUMENT", data);
    },

    async logout() {
      await supabase.auth.signOut();
      await router.replace("/login");
    }
  }
};
