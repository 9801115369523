import {supabase} from "../supabase";
import DatesHelper from "./dates-helper";
import {DateTime} from "luxon";

const filterDateDefault = "month";

let calendarChannel;
const calendar = {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state: {
    items: [],
    itemsFilter: {
      date: filterDateDefault,
      dateText: DatesHelper.textFromFilterType(filterDateDefault),
      base_date: null
    }
  },
  getters: {
    items: state => {
      return state.items || [];
    },
    today: state => {
      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10);
      return (
        state.items?.filter(item => {
          return item.date === today;
        }) || []
      );
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
      // console.log("--> CURR ITEMS : ", state.items);
    },
    filterDateBy(state, type) {
      // console.log("filter by date is here : ", type);
      state.itemsFilter.date = type;
      state.itemsFilter.dateText = DatesHelper.textFromFilterType(type);
    },
    changeBase(state, type) {
      // console.log("change base by date is here : ", type);
      state.itemsFilter.date = type.view;
      state.itemsFilter.dateText = DatesHelper.textFromFilterType(type.view);
      state.itemsFilter.base_date = type.base_date;
    }
  },
  actions: {
    subscribe: async ({rootGetters, dispatch}, payload = {}) => {
      if (calendarChannel) await dispatch("unsubscribe");
      if (!rootGetters["auth/airfieldId"]) return null;

      const handleUpdates = function (update) {
        console.debug("Realtime update:", update);
        dispatch("fetch", payload);
      };

      calendarChannel = supabase
        .channel('calendar-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'calendar',
            filter: `airfield_id=eq.${rootGetters["auth/airfieldId"]}`,
          },
          (payload) => handleUpdates(payload)
        )
        .subscribe()

      dispatch("fetch", payload);
    },
    unsubscribe: async () => {
      if (calendarChannel) {
        await supabase.removeChannel(calendarChannel);
      }
    },

    fetch: async ({rootGetters, state, commit}, payload = {}) => {
      if (!rootGetters["auth/airfieldId"]) return null;
      //console.log("PAYLOAD?", payload);
      let query = supabase.from("calendar")
        .select()
        .eq("airfield_id", rootGetters["auth/airfieldId"])
        .order("start_datetime", {ascending: true});

      if (payload.forToday) {
        const today = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);

        query = query.eq("date", today);
      } else {
        const range = DatesHelper.rangeFromFilterType(state.itemsFilter.date, state.itemsFilter.base_date);
        // console.log("=========> which range?", range);
        query = query
          .gte("end_datetime", range.from.toISO())
          .lte("start_datetime", range.to.toISO());
      }

      const {data, error} = await query;

      if (error) console.error(error);

      var cleaned_array = [];

      if(Array.isArray(data) && data.length > 0){
        // console.log("is array ha");
        for(var i = 0; i < data.length; i++){
          // console.log("time end: ", DateTime.fromISO(data[i].start_datetime).toFormat("yyyy-MM-dd HH:mm:ss"));
          cleaned_array.push({id: data[i].id, name: data[i].event_name, event_type: data[i].event_type, start: DateTime.fromISO(data[i].start_datetime).toFormat("yyyy-MM-dd HH:mm:ss"), end: DateTime.fromISO(data[i].end_datetime).toFormat("yyyy-MM-dd HH:mm:ss"), color: data[i].event_colour, public_message: data[i].public_message, private_message: data[i].private_message});
        }
        // console.log("cleaned_array", cleaned_array);
      }
      
      // console.log("SET ITEM", cleaned_array);

      commit("setItems", cleaned_array);
    },

    filterDateBy: async ({commit, dispatch}, type) => {
      await commit("filterDateBy", type);
      dispatch("subscribe");
    },

    changeBase: async ({commit, dispatch}, type) => {
      await commit("changeBase", type);
      dispatch("subscribe");
    },

    new: async ({rootGetters}, data) => {
      //NEED TO CHANGE THIS TO REFLECT STRUCTURE
      // console.log(DateTime.fromISO(`${data.start_datetime}`).toUTC().toISO());

      switch(data.event_name){
        case 'closed':
          //red? #BA1B1D
          data.event_colour = "#BA1B1D";
        break;
        case 'limited':
          //amber #E98A15
          data.event_colour = "#E98A15";
        break;
        case 'manual-ppr':
          //blue or purple #59114D
          data.event_colour = "#59114D";
        break;
        case 'auto-ppr':
          //green #069E2D
          data.event_colour = "#069E2D";
        break;
        default:
          //blue? #212D40
          data.event_colour = "#212D40";
        break;
      }
      
      const docData = {
        ...data,
        airfield_id: rootGetters["auth/airfieldId"] //,
        // start_datetime: DateTime.fromISO(`${data.start_datetime}T${data.start_datetime}:00`).toUTC().toISO(),
        // end_datetime: DateTime.fromISO(`${data.end_datetime}T${data.end_datetime}:00`).toUTC().toISO(),
        // status: rootGetters["auth/operationMode"] === "attended" ? "New" : "Approved",
        // source: "airfield",
        // aircraft_reg: data.aircraft_reg.toUpperCase(),
        // aircraft_type: data.aircraft_type.toUpperCase(),
      };
      await supabase.from("calendar").insert(docData);
    },

    //keep to see how deletes are completed
    updateEvent: async ({dispatch}, data) => {
      const event_id = data.id;
      delete data.id;

      switch(data.event_name){
        case 'closed':
          //red? #BA1B1D
          data.event_colour = "#BA1B1D";
        break;
        case 'limited':
          //amber #E98A15
          data.event_colour = "#E98A15";
        break;
        case 'manual-ppr':
          //blue or purple #59114D
          data.event_colour = "#59114D";
        break;
        case 'auto-ppr':
          //green #069E2D
          data.event_colour = "#069E2D";
        break;
        default:
          //blue? #212D40
          data.event_colour = "#212D40";
        break;
      }
      
      
      await supabase.from("calendar")
        .update(data)
        .eq("id", event_id);
      dispatch("subscribe");
    },

    deleteEvent: async ({dispatch}, data) => {
      await supabase.from("calendar")
        .delete()
        .eq("id", data.id);
      dispatch("subscribe");
    },

    // markAsRejected: async (_, data) => {
    //   await supabase.from("arrival")
    //     .update({
    //       status: "Rejected",
    //       rejection_message: data.rejectionMessage
    //     })
    //     .eq("id", data.itemId);
    // }
  }
};

export default calendar;
