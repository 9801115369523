import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#38405D",
        secondary: "#8c82ec",
        accent: "#3a2ade",
        navcolour: "#38405D",
        navbackground: "#FFFFFF",
        homebuttonbg: "#FFFFFF",
        homebuttoncolour: "#38405D",
        homebgcolour: "#FFFFFF",
        homebgtextcolour: "#38405D",
        homeiconcolour: "#38405D",
        homebgparallaxtextcolour: "#FFFFFF"
      },
      dark: {
        primary: "#38405D", //"#38405D",
        secondary: "#38405D",
        accent: "#3a2ade",
        navcolour: "#FFFFFF",
        navbackground: "#38405D",
        homebuttonbg: "#38405D",
        homebuttoncolour: "#FFFFFF",
        homebgcolour: "#121212",
        homebgtextcolour: "#FFFFFF",
        homeiconcolour: "#EFEFEF",
        homebgparallaxtextcolour: "#FFFFFF"
      }
    }
  }
});
